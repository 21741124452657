@import url("https://fonts.googleapis.com/css?family=Inter:300,400,500,700&display=swap");

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: "Inter", sans-serif;
    margin: 0;
}

.app {
    display: flex;
    position: relative;
}

#main {
    flex-grow: 1;
    overflow: auto;
}

.position-relative {
    position: relative !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #111827;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1D2635;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mediaCard {
    transform: scale(1);
    border: 1px solid #313A47
}

.mediaCard:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
    border: 1px solid #686b73;
}

.mediaCardTitle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.mediaCardSlider::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.youtubeCardTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.whiteLinks a:link {
    color: white;
}

.whiteLinks a:visited {
    color: white;
}